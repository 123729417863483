import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

ChartJS.defaults.color = "#ffffff";
ChartJS.defaults.plugins.legend.onHover = function() { 
  document.body.style.cursor = 'pointer'; 
};
ChartJS.defaults.plugins.legend.onLeave = function() { 
  document.body.style.cursor = 'unset'; 
};

const indices = {
  'SP500': 'S&P 500',
  'NQ100': 'Nasdaq',
  'DOW30': 'Dow Jones Industrial Average',
  'crypto': 'Bitcoin'
}

const plugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, args, options) => {
    const {ctx} = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color || '#0';
    ctx.fillRect(10, 0, chart.width, chart.height);
    ctx.restore();
  }
};

const options = {
  responsive: true,
  // radius
  elements: {
    point: {
      radius: 0.5
    }
  },
  layout: {
    padding: 25
  },
  scales: {
    x: {
      ticks: {
        maxTicksLimit: 9
      }
    },
    y: {
      type: 'linear',
      display: true,
      position: 'left',
    },
    y1: {
      type: 'logarithmic',
      display: true,
      position: 'right',
      reverse: true,
      grid: {
        drawOnChartArea: false,
      },
    },
  },
  plugins: {
    legend: {
      position: 'top',
    },
    tooltip: {
      mode: 'nearest',
      intersect: false
    },
    title: {
      display: true,
      text: 'Correlation Count vs Index Value',
      color: 'white',
      font: {
        size: 25,
        family: 'Quicksand',
        lineHeight: 1
      }
    },
    zoom: {
      zoom: {
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true
        },
        mode: 'x',
      },
      pan: {
        enabled: true,
        mode: 'x',
      },
    },
  },
};

const CHARTPADDING = 100;
const TIER1 = '';

class Correlation extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
          data : []
        }
    }

    componentDidMount() {
      if (!this.props.currentUser.isAuthenticated || this.props.currentUser.currentSubscriptionEndDate === TIER1) {
        return;
      }
      this.props.apiCall('get', '/correlation', this.state)
    .then((data) => {
        console.log(data);
        for (const [key, value] of Object.entries(data)) {
            let chartData = {labels : [], indexValues: [], v63: {}, v126: {}, v252: {}};

            // Initialize data arrays for each threshold (0.5, 0.6, 0.7, 0.8)
            let thresholds = ['0_5', '0_6', '0_7', '0_8'];
            thresholds.forEach(threshold => {
                chartData.v63[threshold] = [];
                chartData.v126[threshold] = [];
                chartData.v252[threshold] = [];
            });

            // Split the price values by commas
            let indexValues = value['price'].split(',');

            // Create an object to store the parsed data for each threshold
            let parsedData = {};

            thresholds.forEach(threshold => {
                parsedData[`v63_${threshold}`] = value[`v63_${threshold}`].split(',');
                parsedData[`v126_${threshold}`] = value[`v126_${threshold}`].split(',');
                parsedData[`v252_${threshold}`] = value[`v252_${threshold}`].split(',');
            });

            // Process the price data and align it with v63, v126, and v252
            for (let i = 0; i < indexValues.length; i++) {
                let indexValuePair = indexValues[i].split(':');
                let date = indexValuePair[0];  // Date from the price data
                let indexValue = parseFloat(indexValuePair[1]);

                // Add the date as a label and the price value
                chartData.labels.push(date);
                chartData.indexValues.push(indexValue);

                // Process data for each threshold, aligning the dates
                thresholds.forEach(threshold => {
                    // Get the current date for each dataset and handle missing dates
                    let v63Value = parsedData[`v63_${threshold}`].find(v => v.split(':')[0] === date);
                    let v126Value = parsedData[`v126_${threshold}`].find(v => v.split(':')[0] === date);
                    let v252Value = parsedData[`v252_${threshold}`].find(v => v.split(':')[0] === date);

                    // Add null if no matching date, otherwise parse the value
                    chartData.v63[threshold].push(v63Value ? parseFloat(v63Value.split(':')[1]) : null);
                    chartData.v126[threshold].push(v126Value ? parseFloat(v126Value.split(':')[1]) : null);
                    chartData.v252[threshold].push(v252Value ? parseFloat(v252Value.split(':')[1]) : null);
                });
            }

            // Add padding to the labels if needed
            for (let i = 0; i < CHARTPADDING; i++) {
                chartData.labels.push(' ');
            }

            let title = value['index'].replace('Term', ' Term').split('_');

            // Define the datasets for all thresholds
            let datasets = [
                {
                    label: indices[title[0]],
                    data: chartData.indexValues,
                    borderWidth: 1.5,
                    borderColor: 'rgba(34, 177, 76, 1)',
                    backgroundColor: 'rgba(34, 177, 76, 0.5)',
                    yAxisID: 'y',
                    hidden: true
                }
            ];

            // Add datasets for v63, v126, and v252 for each threshold
            thresholds.forEach(threshold => {
                datasets.push(
                    {
                        label: `v63_${threshold}`,
                        data: chartData.v63[threshold],
                        borderWidth: 1.5,
                        borderColor: 'rgba(231, 76, 60, 1)',
                        backgroundColor: 'rgba(231, 76, 60, 0.5)',
                        yAxisID: 'y1',
                        hidden: true  // Show only threshold 0.5 by default
                    },
                    {
                        label: `v126_${threshold}`,
                        data: chartData.v126[threshold],
                        borderWidth: 1.5,
                        borderColor: 'rgba(255, 140, 0, 1)',
                        backgroundColor: 'rgba(255, 140, 0, 0.5)',
                        yAxisID: 'y1',
                        hidden: true  // Show only threshold 0.5 by default
                    },
                    {
                        label: `v252_${threshold}`,
                        data: chartData.v252[threshold],
                        borderWidth: 1.5,
                        borderColor: 'rgba(241, 196, 15, 1)',
                        backgroundColor: 'rgba(241, 196, 15, 0.5)',
                        yAxisID: 'y1',
                        hidden: threshold !== '0_5'  // Show only threshold 0.5 by default
                    }
                );
            });

            // Set the state with the chart data for all thresholds
            this.setState({data: [...this.state.data, {data: {
                labels: chartData.labels,
                datasets: datasets
            }}]}, () => {});
        }
      }).catch((err) => {
          console.log(err)
          return;
      });
    }

    render() {
      if (!this.props.currentUser.isAuthenticated) {
        return (<Redirect to={{ pathname: '/login' }} />);
      }
      if (this.props.currentUser.currentSubscriptionEndDate === TIER1) {
        return (<Redirect to={{ pathname: '/dashboard' }} />);
      }
      if (window.innerWidth <= 600) {
        options.maintainAspectRatio = false;
      } else {
        options.maintainAspectRatio = true;
      }
      let lines = [];
      for (let i = 0; i < this.state.data.length; i+=1) {
          lines.push(<div className='row'>
              <Line options={options} plugins={[plugin]} data={this.state.data[i].data} />
          </div>);
      }
      
      return (
          <div>
            {Object.keys(this.state.data).length !== 0 ? (
              <div class='multi-line-chart-wrapper'>
                {lines}
              </div>
            ) : (<div></div>)}
          </div>
      );
    }
}

export default Correlation;